$fontFamily:  "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontSize: 1em;
$borderRadius: 3px;
$disabledOpacity: 0.35;

$customTableColor: rgba(35, 159, 75, 0.61);
$customCellNotColor: #c7ffc4;
$customCellColor: rgba(51, 182, 99, 0.09);

//Header
$headerBorderWidth: 1px;
$headerBorderColor: green;
$headerBgColor: #f6f7f9;
$headerTextColor: #1b1d1f;
$headerFontWeight: normal;
$headerIconTextColor: #1b1d1f;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: #D5D5D5;
$contentBgColor: #ffffff;
$contentTextColor: #222222;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #d6d6d6;
$stateDefaultBgColor: #ffffff;
$stateDefaultTextColor: #555555;

//Active State
$stateActiveBorderColor: #bebebe;
$stateActiveBgColor: #d6d6d6;
$stateActiveTextColor: #212121;

//Highlight State
$stateHighlightBorderColor: #156090;
$stateHighlightBgColor: #186ba0;
$stateHighlightTextColor: #FFFFFF;

//Focus State
$stateFocusBorderColor: #c0c0c0;
$stateFocusBgColor: #eeeeee;
$stateFocusTextColor: #212121;

//Error State
$stateErrorBorderColor: #f44336;
$stateErrorBgColor: #f5554a;
$stateErrorTextColor: #cd0a0a;

//Hover State
$stateHoverBorderColor: #c0c0c0;
$stateHoverBgColor: #eeeeee;
$stateHoverTextColor: #212121;

//Forms
$inputBgColor: #ffffff;
$inputTextColor: #222222;
$invalidInputBorderColor: #f44336;
$inputGroupTextColor: #222222;

.p-widget-header {
  background: $customTableColor 0 0 repeat-x;  /* Old browsers */
  background: -moz-linear-gradient(top, $customTableColor 0%, $customTableColor 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$customTableColor), color-stop(100%,$customTableColor)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $customTableColor 0%,$customTableColor 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $customTableColor 0%,$customTableColor 100%); /* Opera11.10+ */
  background: -ms-linear-gradient(top, $customTableColor 0%,$customTableColor 100%); /* IE10+ */
  background: linear-gradient(to bottom, $customTableColor 0%,$customTableColor 100%); /* W3C */
}

.p-accordion {
  .p-accordion-header {
    background: $headerBgColor;
    border-top: 1px solid $headerBorderColor;

    a {
      color: $headerTextColor;
    }

    &:not(.p-state-active):not(.p-state-disabled):hover {
      background: #ededf0;
    }

    &.p-state-active {
      background: #ffffff;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }

  .p-accordion-content {
    border: 0 none;
  }
}

/* Tabview */
.p-tabview {
  &.p-widget-content {
    border: 0 none;
  }

  .p-tabview-nav {
    background: transparent;

    > li {
      &.p-state-default {
        background: #f6f7f9;
      }

      &.p-state-active {
        background: #ffffff;
        font-weight: normal;
        color: #555555;
      }

      &:not(.p-state-active):not(.p-state-disabled):hover {
        background: #ededf0;
      }
    }
  }
}

/* Spinner */
.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:hover {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}

.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}

.p-slider { position: relative; text-align: left; background: #838688; border: none; -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset; -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset; box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset;}
.p-slider .p-slider-handle { position: absolute; z-index: 2; width: 17px !important; height: 21px  !important; cursor: default; background: white; outline: none; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; border: none; }
.p-slider .p-slider-range { position: absolute; z-index: 1; font-size: .7em; display: block; border: 0; background: #14a4ff; -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset; -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset; box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.p-slider .p-slider-handle.p-state-active { background-position: -17px 0; }

.p-slider-horizontal { height: 6px; }
.p-slider-horizontal .p-slider-handle { top: -2px !important; margin-left: -.6em; }
.p-slider-horizontal .p-slider-range { top: 0; height: 100%; }
.p-slider-horizontal .p-slider-range-min { left: 0; }
.p-slider-horizontal .p-slider-range-max { right: 0; }

.p-slider-vertical { width: .8em; height: 100px; }
.p-slider-vertical .p-slider-handle { left: -.1em !important; margin-left: 0; margin-bottom: -.6em; }
.p-slider-vertical .p-slider-range { left: 0; width: 100%; }
.p-slider-vertical .p-slider-range-min { bottom: 0; }
.p-slider-vertical .p-slider-range-max { top: 0; }

.p-progressbar {
  &.p-progressbar-determinate {
    .p-progressbar-value {
      border: 0 none;
      background: #8ec5fc;
    }

    .p-progressbar .p-progressbar-label {
      color: #222222;
    }
  }

  &.p-progressbar-indeterminate {
    background: #8ec5fc;

    .p-progressbar-value {
      border: 0 none;
      background-color: $stateHighlightBgColor;
    }
  }
}

.p-fileupload-choose:not(.p-state-disabled):hover,
.p-fileupload-choose.p-state-focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}

.p-fileupload-choose:not(.p-state-disabled):active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}

/* Checkbox and Radio */
.p-chkbox-box.p-state-active,
.p-radiobutton-box.p-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #FFFFFF;
}

.p-chkbox-box.p-state-focus,
.p-radiobutton-box.p-state-focus  {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}

.p-chkbox-box.p-state-focus.p-state-active {
  background: #186ba0;
}

/* Inputs */
.p-inputtext {
  background: #ffffff;
  color: #222222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.p-inputtext.p-state-focus,
.p-inputtext:focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}

/* InputSwitch */
.p-inputswitch-on {
  background: #186ba0 !important;
  color: #ffffff !important;
}

.p-paginator {
  background: none !important;
}
.p-paginator .p-paginator-page.p-state-active {
  background: #186ba0;
  color: #ffffff;
  border-color: #156090;
}

/* DataTable  */
.p-datatable {
  th {
    &.p-state-default {
      background: #ebedf0;
      border-color: #d9d9d9;
    }

    &.p-sortable-column:not(.p-state-active):hover {
      background: #d3d5d8;
      border-color: #d9d9d9;
    }

    &.p-state-active {
      background: #186ba0;
      color: #ffffff;
    }
  }

  tbody {
    > tr.p-widget-content {
      border-color: #d9d9d9;

      &.p-datatable-odd {
        background-color: #fafafb;
      }

      &.p-state-highlight {
        background-color: $stateHighlightBgColor;
        color: $stateHighlightTextColor;
      }
    }
  }

  tfoot {
    td {
      &.p-state-default {
        background: #ebedf0;
        border-color: #d9d9d9;
      }
    }
  }
}

/* TurboTable */
.p-table {

  .p-table-thead > tr > th,
  .p-table-tfoot > tr > td {
    background-color: $customTableColor;
    border-color: green;
  }

  .p-sortable-column:not(.p-state-highlight):hover {
    background-color: $customCellNotColor;
    border-color: green;
  }

  .p-table-tbody > tr:nth-child(even) {
    background-color: $customCellColor;

    &.p-state-highlight {
      background-color: $stateHighlightBgColor;
      color: $stateHighlightTextColor;
    }

    &.p-contextmenu-selected {
      background-color: lighten($stateHighlightBgColor, 20%);
      color: $stateHighlightTextColor;
    }
  }
}

/* TurboTable */
.p-treetable {
  .p-treetable-thead > tr > th,
  .p-treetable-tfoot > tr > td {
    background-color: #ebedf0;
    border-color: #d9d9d9;
  }

  .p-sortable-column:not(.p-state-highlight):hover {
    background-color: #d3d5d8;
    border-color: #d9d9d9;
  }
}

/* Panel */
.p-panel.p-widget {
  padding: 0;

  .p-panel-titlebar.p-corner-all {
    -moz-border-radius-bottom-left: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottom-right: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .p-panel-titlebar {
    border-width: 0 0 1px 0;
  }

  .p-panel-titlebar-icon span {
    position: relative;
    top: 1px;
  }

}

/* TreeTable */
.p-treetable {
  th.p-state-default {
    background: #ebedf0;
    border-color: #d9d9d9;
  }
}

/* p-selectButons */
.p-widget-header .p-button,
.p-widget-content .p-button,
.p-widget.p-button,
.p-button {

  // border: 1px solid #2399e5;
  // color: #FFFFFF;
  background: #d5d8da;
  -webkit-transition: background-color .2s;
  -moz-transition: background-color .2s;
  transition: background-color .2s;

  &:enabled:hover,
  &:focus {
    border: 1px solid #1f89ce;
    background: #1f89ce;
    outline: 0 none;
    color: #ffffff;
  }

  &:enabled:active {
    border: 1px solid #156090;
    background: #186ba0;
    color: #ffffff;
  }
}

.p-togglebutton.p-button.p-state-active,
.p-selectbutton .p-button.p-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #FFFFFF;
}

.p-multiselect {
  .p-multiselect-label {
    background-color: #ffffff;
  }
}

.p-select.p-state-focus, .p-multiselect.p-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}

/* Growl */
.p-growl-item-container.p-state-highlight {
  &.p-growl-message-info {
    background-color: #2196f3;
    border-color :#2196f3;
  }

  &.p-growl-message-error {
    background-color: #f44336;
    border-color :#f44336;
  }

  &.p-growl-message-warn {
    background-color: #FFB300;
    border-color :#FFB300;
  }

  &.p-growl-message-success {
    background-color: #4CAF50;
    border-color :#4CAF50;
  }
}

/* TabMenu */
.p-tabmenu {
  border: 0 none;

  .p-tabmenu-nav {
    background: none;

    > li {
      &.p-state-default {
        background: #f6f7f9;
      }

      &.p-state-active {
        background: #ffffff;
        font-weight: normal;
        color: #555555;
      }

      &:not(.p-state-active):not(.p-state-disabled):hover {
        background: #ededf0;
      }
    }
  }
}

/* Menus */
.p-menuitem-content {
  & a.p-ripple {
    text-decoration: none;
  }

}

/* PanelMenu */
.p-panelmenu .p-panelmenu-header.p-state-active,
.p-panelmenu .p-panelmenu-header.p-state-active a {
  border-color: #156090;
  background: #186ba0;
  color: #FFFFFF;
}

/* DatePicker */
.p-datepicker.p-widget {
  padding: 0;

  .p-datepicker-header {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;

    a {
      &:hover {
        border-width: 1px;
      }
    }
  }

  .p-datepicker-calendar {
    margin: 0;

    thead th {
      background-color: #f6f8fa;
      padding: 8px;
    }

    td {
      border-bottom: 1px solid rgba(213, 213, 213, 0.5);
      padding: 0;

      a {
        border: 0 none;
        text-align: center;
        padding: 8px;

        &.p-state-highlight {
          background-color: #d6d6d6;
          color: #212121;
        }

        &.p-state-active {
          background-color: #186ba0;
          color: #ffffff;
        }
      }
    }

    tr:last-child td {
      border-bottom: 0 none;
    }
  }

  .p-timepicker {
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }
}

/* Steps */
.p-steps .p-steps-item.p-state-highlight .p-menuitem-link {
  color: #ffffff;
}

/* Dialog */
.p-dialog.p-widget .p-dialog-titlebar {
  padding: 1em 1.5em;
}

.p-dialog.p-widget .p-dialog-titlebar .p-dialog-title {
  font-size: 1.25em;
}

.p-dialog.p-widget .p-dialog-content {
  padding: 1em 1.5em;
}

.p-dialog-content {
  height: 100% !important;
}

/* Schedule */
.fc {
  .fc-button-group {
    .p-state-active {
      border: 1px solid #156090;
      background: #186ba0;
      color: #ffffff;
    }
  }
}

.fc-scroller.fc-day-grid-container {
  height: 100% !important;
}
